const model = {
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  password: '',
  confirm_password: '',
  company: '',
  value_chain: '',
};

const form = [
  { 
    type: 'text',
    name: 'first_name',
    label: 'Firstname',
    placeholder: 'Your firstname',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'last_name',
    label: 'Lastname',
    placeholder: 'Your lastname',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'username',
    label: 'Username',
    placeholder: 'Your user name',
    component: 'el-input',
    required: true,
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    placeholder: 'Your email address',
    component: 'el-input',
    required: true,
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    placeholder: 'Your password',
    component: 'el-input',
    required: true,
    is_password: true,
  },
  {
    type: 'password',
    name: 'confirm_password',
    label: 'Confirm password',
    placeholder: 'Confirm your password',
    component: 'el-input',
    required: true,
    is_password: true,
  },
  {
    type: 'text',
    name: 'company',
    label: 'Company',
    placeholder: 'Your company name',
    required: false,
    component: 'el-input',
  },
  {
    type: 'select',
    name: 'value_chain',
    label: 'Value chain',
    placeholder: 'Select your value chain',
    component: 'el-select',
    required: false,
    options: [
      { label: 'Mining', value: 'mining' },
      { label: 'Refining', value: 'refining' },
      { label: 'CAM/PCAM', value: 'cam_pcam' },
      { label: 'Cell manufacturing', value: 'cell_manufacturing' },
      { label: 'Battery assembly', value: 'battery_assembly' },
      { label: 'Car manufacturing', value: 'car_manufacturing' },
      { label: 'Collecting and sorting', value: 'collecting_and_sorting' },
      { label: 'Test/Discharging', value: 'test_discharging' },
      { label: 'Second life', value: 'second_life' },
      { label: 'Recycling', value: 'recycling' },
      { label: 'Other', value: 'other' },
    ],
  },
];

const rules = {
  first_name: [
    {
      type: 'required',
      message: 'Please enter your firstname',
    },
  ],
  last_name: [
    {
      type: 'required',
      message: 'Please enter your lastname',
    },
  ],
  username: [
    {
      type: 'required',
      message: 'Please enter your display name',
    },
  ],
  email: [
    {
      type: 'required',
      message: 'Please enter your email address',
    },
    {
      type: 'email',
      message: 'Please enter a valid email address',
    },
  ],
  password: [
    {
      type: 'required',
      message: 'Please enter your password',
    },
    {
      type: 'minLength',
      minLength: 8,
    },
  ],
  confirm_password: [
    {
      type: 'required',
      message: 'Please confirm your password',
    },
    {
      type: 'confirm',
      confirmField: 'password',
      message: 'Passwords do not match',
    },
  ],
  company: [],
  value_chain: [],
};

export { model, form, rules };
